import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import useStyles from "./styles";
import { adminRoute } from "../../../utils";
import { logout } from "../../../store/actions/user";
import { useDispatch, useSelector } from "react-redux";
import {
  BLOCK_USERS,
  BLOG,
  DISCLAIMER_TERMS,
  RPC,
  WHITELABEL_CONFIG,
} from "../../../constants";
import { resetMenu, setMenu, setSubMenu } from "../../../store/actions/nav";
import { ArrowDropDown } from "@material-ui/icons";

interface NavProps {
  title: string;
  part: string;
  icon: string;
  show: boolean;
  submenu?: any;
}

const nav: NavProps[] = [
  {
    title: "List Pool",
    part: adminRoute("/campaigns"),
    icon: "icon-list-campaign",
    show: true,
  },
  // {
  //   title: "List TBA Pool",
  //   part: adminRoute("/tba-campaigns"),
  //   icon: "icon-list-campaign",
  // },
  {
    title: "Blogs",
    part: adminRoute("/blog"),
    icon: "",
    show: BLOG,
  },
  {
    title: "List User",
    part: "",
    icon: "icon-users",
    show: true,
    submenu: [
      {
        title: "Users",
        part: adminRoute("/users"),
        icon: "icon-list-campaign",
        show: true,
      },
      {
        title: "User Terms Logs",
        part: adminRoute("/user-terms-log"),
        icon: "icon-users",
        show: true,
      },
    ],
  },
  // {
  //   title: "User Referral",
  //   part: adminRoute("/referrals"),
  //   icon: "icon-history",
  // },
  {
    title: "KYC User List",
    part: adminRoute("/kyc-users"),
    icon: "icon-users",
    show: true,
  },
  {
    title: "Staking Pools",
    part: "",
    icon: "icon-list-campaign",
    show: true,
    submenu: [
      {
        title: "Pools",
        part: adminRoute("/staking"),
        icon: "icon-list-campaign",
        show: true,
      },
      {
        title: "User Staking",
        part: adminRoute("/staking/user-info"),
        icon: "icon-users",
        show: true,
      },
    ],
  },
  {
    title: "List Admin",
    part: adminRoute("/admins"),
    icon: "icon-users",
    show: true,
  },
  {
    title: "Statistic",
    part: adminRoute("/statistic"),
    icon: "icon-setting",
    show: true,
  },
  {
    title: "RPC URLs",
    part: adminRoute("/rpc-url"),
    icon: "icon-rpc",
    show: RPC,
  },
  {
    title: "Terms & Disclaimer",
    part: adminRoute("/term-and-privacy"),
    icon: "icon-setting",
    show: DISCLAIMER_TERMS,
  },
  {
    title: "Config",
    part: adminRoute("/config"),
    icon: "icon-setting",
    show: true,
  },
  {
    title: "Block Users",
    part: adminRoute("/block-users"),
    icon: "icon-setting",
    show: BLOCK_USERS,
  },
];

if (WHITELABEL_CONFIG) {
  nav.push({
    title: "Config",
    part: adminRoute("/config"),
    icon: "icon-setting",
    show: RPC,
    submenu: [
      {
        title: "Landing",
        part: adminRoute("/landing"),
        icon: "",
        show: true,
      },
      {
        title: "Faqs",
        part: adminRoute("/faqs"),
        icon: "",
        show: true,
      },
      {
        title: "Menu",
        part: adminRoute("/menu"),
        icon: "",
        show: true,
      },
      {
        title: "Tiers",
        part: adminRoute("/tiers"),
        icon: "",
        show: true,
      },
      {
        title: "Terms of Services",
        part: adminRoute("/tos"),
        icon: "",
        show: true,
      },
      {
        title: "Privacy Policy",
        part: adminRoute("/privacyPolicy"),
        icon: "",
        show: true,
      },
      {
        title: "Theme Options",
        part: adminRoute("/ThemeOptions"),
        icon: "",
        show: true,
      },
      {
        title: "Need help",
        part: adminRoute("/needHelp"),
        icon: "",
        show: true,
      },
      {
        title: "Social Media Links",
        part: adminRoute("/socialMediaLinks"),
        icon: "",
        show: true,
      },
    ],
  });
}

const NavLeft = (props: any) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { smallLeft, location } = props;
  const [navLeft] = React.useState(nav);

  const logoutUser = () => {
    // eslint-disable-next-line no-restricted-globals
    if (!confirm("Do you want logout?")) {
      return false;
    }
    dispatch(logout());
  };

  const [openMenu, setOpenMenu] = useState(false);

  const { menu } = useSelector((state: any) => state?.menu);

  useEffect(() => {
    const savedMenu = localStorage.getItem("activeMenu");
    if (savedMenu) {
      dispatch(setMenu(Number(savedMenu)));
    }
  }, []);

  const handleMenuClick = (index: number) => {
    if (menu === index) {
      // Close the menu
      dispatch(resetMenu());
      localStorage.removeItem("activeMenu");
    } else {
      // Set the clicked menu as active
      dispatch(setMenu(index));
      localStorage.setItem("activeMenu", index.toString());
    }
  };

  const handleSubMenuClick = (menuIndex: number, subMenuIndex: number) => {
    dispatch(setSubMenu(menuIndex, subMenuIndex));
  };

  return (
    <ul className={styles.navLeft}>
      {navLeft
        .filter((item) => {
          if (item.show) return item;
        })
        .map((item, itemIndex) => {
          return (
            <div key={itemIndex}>
              <li
                onClick={() => handleMenuClick(itemIndex)}
                key={itemIndex}
                className={
                  styles.itemNavLeft +
                  " " +
                  (location?.pathname === item.part && "active")
                }
              >
                {item?.submenu && item?.submenu?.length > 0 ? (
                  <div
                    className={
                      styles.linkItemNavLeft +
                      " " +
                      (location?.pathname === item.part && "active")
                    }
                  >
                    <i
                      className={
                        (smallLeft && " icon-small") + " icon " + item.icon
                      }
                    ></i>
                    {!smallLeft && item.title}
                    {!smallLeft && (
                      <span
                        style={{
                          marginLeft: "auto",
                        }}
                      >
                        <ArrowDropDown />
                      </span>
                    )}
                  </div>
                ) : (
                  <Link
                    onClick={() => {
                      // if (item?.submenu) {
                      window.scrollTo({ top: 0, behavior: "smooth" });
                      // }
                    }}
                    to={item.part}
                    className={
                      styles.linkItemNavLeft +
                      " " +
                      (location?.pathname === item.part && "active")
                    }
                  >
                    <i
                      className={
                        (smallLeft && " icon-small") + " icon " + item.icon
                      }
                    ></i>
                    {!smallLeft && item.title}
                  </Link>
                )}
              </li>
              {item.submenu &&
                item?.submenu?.length > 0 &&
                item.submenu &&
                menu === itemIndex && (
                  <ul>
                    {item.submenu.map(
                      (subMenu: any, subIndex: any) =>
                        subMenu?.show && (
                          <li
                            onClick={() => {
                              handleSubMenuClick(itemIndex, subIndex);
                              window.scrollTo({ top: 0, behavior: "smooth" });
                            }}
                            key={subIndex}
                            className={
                              styles.itemNavLeft +
                              " " +
                              (location?.pathname === subMenu.part && "active")
                            }
                            style={{
                              marginLeft: "50px",
                              width: "calc(100% - 50px)",
                            }}
                          >
                            <Link
                              to={subMenu.part}
                              className={
                                styles.subItemNavLeft +
                                " " +
                                (location?.pathname === subMenu.part &&
                                  "active")
                              }
                            >
                              {subMenu.icon && (
                                <i
                                  className={
                                    (smallLeft && " icon-small") +
                                    " icon " +
                                    subMenu.icon
                                  }
                                ></i>
                              )}
                              {!smallLeft && subMenu.title}
                            </Link>
                          </li>
                        )
                    )}
                  </ul>
                )}
            </div>
          );
        })}

      <li className={styles.itemNavLeft}>
        <a onClick={logoutUser} className={styles.linkItemNavLeft}>
          <i
            className={(smallLeft && " icon-small") + " icon icon-setting"}
          ></i>
          {!smallLeft && "Logout"}
        </a>
      </li>
    </ul>
  );
};

export default withRouter(NavLeft);
